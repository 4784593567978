<template>
  <ion-page>
    <WhiteHeader :back-button="false" title="Mes rapports hebdo" />
    <ion-content>
      <ion-refresher
        class="refresher refresher-time-reports"
        @ionRefresh="doRefresh($event)"
        @click="doRefresh($event)"
        slot="fixed"
      >
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-card
        v-for="(week, index) in weeks"
        :key="index"
        class="week-card"
        @click="openDetail(week)"
      >
        <ion-card-header>
          <ion-card-title class="text-align-center">
            <strong class="text-danger">Semaine {{ week.week }}</strong>
            <br />
            du <strong>{{ week.formattedStart }}</strong> au
            <strong>{{ week.formattedEnd }}</strong>
          </ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <div class="days">
            <div
              class="day"
              :class="{
                draft:
                  reports &&
                  reports[day.date] &&
                  reports[day.date].status === 'draft',
                sent:
                  reports &&
                  reports[day.date] &&
                  reports[day.date].status === 'sent',
              }"
              v-for="day in week.days"
              :key="day"
            >
              {{ day.label }}
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import { addOutline } from 'ionicons/icons';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import { getInfo, getParams } from '@/services/users';
import { makeWeek } from '@/services/utils/reportUtils';

export default {
  components: {
    WhiteHeader,
  },

  setup() {
    return {
      addOutline,
    };
  },

  ionViewDidEnter() {
    this.getStatuses();
  },

  data() {
    return {
      reports: {},
      weeks: [],
    };
  },

  async mounted() {
    this.agent = (await getInfo()).agent;
    this.initList();
  },

  methods: {
    initList() {
      // Take last 4 weeks, including current
      const now = dayjs();

      const weeks = [
        makeWeek(now.startOf('week')),
        makeWeek(now.startOf('week').add(-7, 'days')),
        makeWeek(now.startOf('week').add(-14, 'days')),
        makeWeek(now.startOf('week').add(-21, 'days')),
      ];

      this.weeks = weeks;
    },

    async getStatuses() {
      const response = await axios.get(
        '/driver/time-report',
        await getParams(),
      );
      this.reports = response.data;
    },

    async doRefresh(event) {
      try {
        await this.getStatuses();
        this.initList();
        event.target.complete();
      } catch (e) {
        event.target.cancel();
      }
    },

    openDetail(week) {
      this.$router.push(`/time-reports/${week.days[0].date}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.week-card {
  padding-top: 6px;
  padding-bottom: 6px;
}

.days {
  display: flex;
  justify-content: space-around;

  .day {
    background-color: white;
    border: 1px solid gray;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 4px;

    &.draft {
      background-color: var(--ion-color-primary);
      border-color: var(--ion-color-primary);
      color: white;
    }

    &.sent {
      background-color: var(--ion-color-success);
      border-color: var(--ion-color-success);
      color: white;
    }
  }
}
</style>
