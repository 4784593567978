<template>
  <ion-row>
    <ion-col size="9" class="row-align-center">
      <ion-label
        class="title-part-form-driver"
        @click="onChangeToggleComment(!toggleComment)"
        >Commentaire</ion-label
      >
      <ion-button
        v-if="comments"
        color="danger"
        size="small"
        class="ion-margin-start"
        @click="onChangeComments()"
      >
        <ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col class="padding-0 justify-content-end">
      <ion-toggle
        @ionChange="onChangeToggleComment($event.target.checked)"
        :checked="toggleComment"
        mode="md"
        color="dark"
      ></ion-toggle>
    </ion-col>
  </ion-row>

  <ion-row v-show="toggleComment">
    <ion-col>
      <ion-textarea
        name="comments"
        class="textarea-form-driver"
        rows="4"
        maxlength="2000"
        @ionChange="onChangeComments($event.target.value)"
        :value="comments"
        :disabled="oldRentalCouponSending"
        :class="{
          'limit-reached': comments && comments.length >= 2000,
        }"
      ></ion-textarea>
      <div
        v-if="comments"
        class="ion-text-end ion-margin-end"
        :class="comments.length >= 2000 ? 'text-danger' : 'color-green'"
      >
        {{ comments.length }} / 2000
      </div>
    </ion-col>
  </ion-row>

  <attachments
    v-show="toggleComment"
    v-model:attachments="filteredAttachments"
    v-model:waiting-files="waitingFiles"
    v-model:attachments-to-delete="attachmentsToDelete"
    v-model:attachments-to-update="attachmentsToUpdate"
    :type-vue="typeVue"
    :status="status"
    :files-too-big="filesTooBig"
    kind="comments"
  />

  <hr />
</template>

<script>
import {
  IonRow,
  IonCol,
  IonLabel,
  IonToggle,
  IonTextarea,
  IonIcon,
  IonButton,
} from '@ionic/vue';
import utils from '@/services/utils/Utils';
import Attachments from '@/components/driver/rentalCoupon/components/Attachments';
import { getInfo } from '@/services/users';
import { trashOutline } from 'ionicons/icons';

export default {
  name: 'Options',
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonToggle,
    IonTextarea,
    IonIcon,
    IonButton,
    Attachments,
  },
  props: [
    'toggleMachineCounter',
    'toggleComment',
    'pricingFuel',
    'machine_counter_start',
    'machine_counter_end',
    'comments',
    'oldRentalCouponSending',
    'typeMission',

    'status',

    'attachments',
    'typeVue',
    'filesTooBig',
  ],

  setup() {
    return {
      trashOutline,
    };
  },

  data() {
    return {
      numberAreaWorksite: utils.generateArray(10),
      waitingFiles: [],
      attachmentsToDelete: [],
      attachmentsToUpdate: {},
    };
  },

  mounted() {
    // only for "TARN POMPAGE", init comments with default value
    getInfo().then(res => {
      if (
        res &&
        res.userData &&
        res.userData.company_id &&
        // "TARN POMPAGE" id
        res.userData.company_id === 3404
      ) {
        this.onChangeComments(
          'Heure d’arrivée chantier : \nHeure début pompage : \nHeure fin pompage : \nHeure départ chantier :',
        );
        this.onChangeToggleComment(true);
      }
    });
  },

  methods: {
    onChangeToggleComment(value) {
      this.$emit('update:toggleComment', value);
    },

    onChangeComments(value) {
      this.$emit('update:comments', value);
    },

    resetFilesInformation() {
      this.waitingFiles = [];
      this.attachmentsToDelete = [];
      this.attachmentsToUpdate = {};
    },

    getWaitingFiles() {
      return this.waitingFiles;
    },

    getAttachmentsToDelete() {
      return this.attachmentsToDelete;
    },

    getAttachmentsToUpdate() {
      return this.attachmentsToUpdate;
    },
  },

  computed: {
    filteredAttachments: {
      get() {
        if (!this.attachments) return [];

        return this.attachments.filter(a => a.kind === 'comments');
      },

      set(newVal) {
        this.$emit('update:attachments', newVal);
      },
    },
  },
};
</script>

<style scoped>
  hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .flex-item {
    display: flex;
    justify-content: space-around;
  }

  .center-element {
    display: flex;
    align-items: center;
  }

  ion-select {
    border: 1px solid black;
    border-radius: 4px;
  }
  
  .mr-0 {
    margin-right: 0;
  }
  .ml-0 {
    margin-left: 0;
  }
</style>
