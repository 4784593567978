<template>
  <div>
    <!-- Mode selector (fragment) -->
    <ion-row class="ion-justify-content-around">
      <ion-col mode="ios">
        <!-- User has the choice: available or new -->
        <ion-segment
          v-if="contacts && contacts.length && contacts[0].id"
          :value="mode"
          @ionChange="$emit('update:mode', $event.detail.value)"
          :disabled="limitedMode"
        >
          <ion-segment-button value="available">
            <ion-label>Sélectionnez un contact</ion-label>
          </ion-segment-button>

          <ion-segment-button value="new">
            <ion-label>Nouveau contact</ion-label>
          </ion-segment-button>
        </ion-segment>

        <!-- No choice, only new contact -->
        <h4 v-else>Nouveau contact</h4>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <!-- Available: Select -->
        <ion-select
          v-if="mode === 'available'"
          :value="id"
          @ionChange="$emit('update:id', $event.detail.value)"
          placeholder="Contact non-renseigné"
          :interface-options="{ subHeader: 'Sélectionnez un contact' }"
          ok-text="Valider"
          cancel-text="Annuler"
          class="form-group-bordered"
          :disabled="limitedMode"
        >
          <ion-select-option
            v-for="contact in contacts"
            :key="contact.id"
            :value="contact.id"
          >
            {{ contact.name }}
          </ion-select-option>
        </ion-select>

        <!-- New: Contact name -->
        <ion-item v-else class="form-group-bordered">
          <ion-label position="floating">Contact</ion-label>
          <ion-input
            mode="md"
            :value="name"
            @ionChange="$emit('update:name', $event.detail.value)"
            :disabled="limitedMode"
            :required="mandatoryName"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <!-- Both: Ability to view & change email -->
    <ion-row>
      <ion-col>
        <ion-item class="form-group-bordered">
          <ion-label position="floating">Email</ion-label>
          <ion-input
            mode="md"
            type="email"
            inputmode="email"
            :value="email"
            @ionChange="$emit('update:email', $event.detail.value)"
          >
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import {
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
} from '@ionic/vue';

export default {
  props: [
    // Sync
    'mode',
    'id',
    'name',
    'email',

    // Read-only
    'contacts',
    'limitedMode', // Limit changes
    'mandatoryName',
  ],

  emits: ['update:mode', 'update:id', 'update:name', 'update:email'],

  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonItem,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonInput,
  },
};
</script>

<style scoped>
ion-row {
  width: 100%;
}

ion-segment-button ion-label {
  font-size: 12px;
}

h4 {
  font-weight: bold;
}

.form-group-bordered {
  border: 1px solid black;
  border-radius: 4px;
}

ion-segment,
.form-group-bordered {
  margin-bottom: 10px;
}
</style>
