<template>
  <div>
    <div
      class="flex"
      v-for="(attachment, key) in attachments"
      :key="attachment.id || attachments.randomId"
    >
      <ion-item v-show="key === 0 || showMore" lines="none" class="w-100">
        <ion-img
          slot="start"
          :src="getAttachmentIconPath(attachment.extension)"
        ></ion-img>

        <ion-label
          v-if="!attachment.editing"
          @click="displayAttachment(attachment)"
        >
          {{ attachment.newName || attachment.name }}
        </ion-label>

        <ion-input
          v-else
          :value="
            attachment.newName
              ? attachment.newName.slice(0, attachment.newName.lastIndexOf('.'))
              : attachment.name.slice(0, attachment.name.lastIndexOf('.'))
          "
          @change="temporaryName = $event.target.value"
          fill="solid"
        />

        <!-- v-show because we need popover button in the DOM to make popover work-->
        <ion-button
          v-if="status !== 'send' || typeVue === 'create' || filesTooBig"
          v-show="!attachment.editing"
          slot="end"
          :id="attachment.id || attachment.randomId"
          size="small"
          color="dark"
          fill="clear"
        >
          <ion-icon
            class="popover-toggle"
            slot="icon-only"
            :icon="ellipsisVertical"
          ></ion-icon>
        </ion-button>

        <ion-button
          v-show="attachment.editing"
          slot="end"
          fill="clear"
          color="success"
          size="small"
          @click="onAttachmentNameInputChange(attachment)"
        >
          <ion-icon slot="icon-only" :icon="checkmark"></ion-icon>
        </ion-button>
      </ion-item>

      <ion-popover
        v-if="status !== 'send' || typeVue === 'create' || filesTooBig"
        :trigger="attachment.id || attachment.randomId"
        :dismiss-on-select="true"
      >
        <ion-content>
          <ion-list lines="none">
            <ion-item
              @click="
                attachment.editing = true;
                $forceUpdate();
              "
              :button="true"
            >
              <ion-icon
                color="dark"
                slot="start"
                :icon="createOutline"
              ></ion-icon>
              <ion-label>Modifier</ion-label>
            </ion-item>

            <ion-item @click="deleteAttachment(attachment)" :button="true">
              <ion-icon
                color="danger"
                slot="start"
                :icon="trashOutline"
              ></ion-icon>
              <ion-label color="danger">Supprimer</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-popover>
    </div>

    <ion-row
      v-if="attachments && attachments.length > 1"
      class="ion-justify-content-center show-more"
    >
      <ion-button size="small" fill="outline" @click="showMore = !showMore">
        <ion-label v-if="showMore">Voir moins</ion-label>
        <ion-label v-else
          >Voir {{ attachments.length - 1 }} fichiers de plus</ion-label
        >

        <ion-icon
          slot="end"
          class="show-more-icon"
          :class="{ expanded: showMore }"
          :icon="chevronDown"
        ></ion-icon>
      </ion-button>
    </ion-row>

    <input
      ref="files"
      type="file"
      multiple="true"
      accept=".doc,.docx,.odt,.pdf,.png,.jpg,.jpeg,.bmp,.webp,.mp4,.mkv,.avi,.mov,.3gp"
      hidden
      @change="onFileInputChange"
    />

    <ion-row
      v-if="compressing"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-spinner name="dots"></ion-spinner>
      <ion-label class="ion-padding-start">
        <strong>Re-dimensionnement des photos</strong>, merci de patienter.
      </ion-label>
    </ion-row>

    <ion-row
      v-if="status !== 'send' || typeVue === 'create'"
      class="ion-justify-content-center"
    >
      <ion-button
        color="dark"
        fill="clear"
        class="add-attachment"
        @click="$refs.files.click()"
        :disabled="compressing"
      >
        <ion-icon slot="start" :icon="attachOutline"></ion-icon>
        Ajouter un document
      </ion-button>
    </ion-row>
  </div>
</template>

<script>
import {
  IonRow,
  IonImg,
  IonLabel,
  IonIcon,
  IonButton,
  IonItem,
  IonPopover,
  IonList,
  IonInput,
  IonSpinner,
  toastController,
} from '@ionic/vue';
import {
  chevronDown,
  attachOutline,
  createOutline,
  ellipsisVertical,
  checkmark,
  trashOutline,
} from 'ionicons/icons';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

import utils from '@/services/utils/Utils';
import { getParams } from '@/services/users';

const MAX_FILE_SIZE = 1024 * 1024;
const MAX_IMAGE_SIZE = 2560;
const JPG_QUALITY = 0.89;

export default {
  components: {
    IonRow,
    IonImg,
    IonLabel,
    IonIcon,
    IonButton,
    IonItem,
    IonList,
    IonPopover,
    IonInput,
    IonSpinner,
  },

  props: {
    attachments: Array, // All attachments (old, new, modified, etc), only for the current instance
    /**
     * ⚠️ When using <Attachments> within MissionReport.vue, please note that
     * only `attachments` is **specific** to each question.
     * `waitingFiles`, `attachmentsToDelete` & `attachmentsToUpdate`
     * are shared between **several** custom questions and "normal" questions.
     */
    waitingFiles: Array, // Only new attachments ("waiting" to be uploaded), but for several instances
    // Please note the nuance between "attachments" and "waitingFiles" (array vs object)
    attachmentsToDelete: Array,
    attachmentsToUpdate: Object,
    kind: String,
    typeVue: String,
    status: String,
    filesTooBig: Boolean,
  },

  emits: [
    'update:attachments',
    'update:waitingFiles',
    'update:attachmentsToDelete',
    'update:attachmentsToUpdate',
  ],

  setup() {
    return {
      chevronDown,
      attachOutline,
      createOutline,
      ellipsisVertical,
      checkmark,
      trashOutline,
    };
  },

  data() {
    return {
      showMore: false,
      eventKind: null,
      temporaryName: '',
      compressing: false,
    };
  },

  methods: {
    getAttachmentIconPath: utils.getAttachmentIconPath,

    async displayAttachment(attachment) {
      // avoid trying to open waiting file
      if (!attachment.id) return;

      const response = await axios.get(
        '/attachment/' + attachment.id,
        await getParams(),
      );

      const toast = await toastController.create({
        buttons: [
          {
            text: 'Cliquez ici',
            handler: () => {
              window.open(response.data, '_blank');
            },
          },
        ],
        message: 'Votre fichier est prêt !',
        duration: 15000,
        color: 'primary',
        position: 'bottom',
      });

      await toast.present();
    },

    onFileInputChange(event) {
      const mappedAddedFiles = [...event.target.files].map(a => {
        a.kind = this.kind;
        a.extension = a.name.substring(a.name.lastIndexOf('.') + 1);
        a.randomId = (Math.random() + 1).toString(36).substring(2);

        return a;
      });

      const updatedAttachments = [...this.attachments, ...mappedAddedFiles];
      this.$emit('update:attachments', updatedAttachments);

      const updatedWaitingFiles = [...this.waitingFiles, ...mappedAddedFiles];
      this.$emit('update:waitingFiles', updatedWaitingFiles);
    },

    deleteAttachment(attachment) {
      const updatedAttachments = [...this.attachments];
      updatedAttachments.splice(updatedAttachments.indexOf(attachment), 1);
      this.$emit('update:attachments', updatedAttachments);

      if (!attachment.id) {
        const updatedWaitingFiles = [...this.waitingFiles];
        updatedWaitingFiles.splice(updatedWaitingFiles.indexOf(attachment), 1);
        this.$emit('update:waitingFiles', updatedWaitingFiles);
      } else {
        this.$emit('update:attachmentsToDelete', [
          ...this.attachmentsToDelete,
          attachment.id,
        ]);
      }
    },

    onAttachmentNameInputChange(attachment) {
      if (!this.temporaryName) {
        attachment.editing = false;
        this.$forceUpdate();
        return;
      }

      const fullNewName = `${this.temporaryName}.${attachment.extension}`;

      if (attachment.id) {
        const updatedAttachmentsToUpdate = { ...this.attachmentsToUpdate };
        updatedAttachmentsToUpdate[attachment.id] = this.temporaryName;
        this.$emit('update:attachmentsToUpdate', updatedAttachmentsToUpdate);
      } else {
        const updatedWaitingFiles = this.waitingFiles.map(a => {
          if (a.randomId === attachment.randomId) {
            a.newName = fullNewName;
          }

          return a;
        });
        this.$emit('update:waitingFiles', updatedWaitingFiles);
      }

      attachment.editing = false;
      attachment.newName = fullNewName;
      this.$forceUpdate();
    },

    compressImage(file) {
      return new Promise((resolve, reject) => {
        let imageUrl;
        try {
          imageUrl = URL.createObjectURL(file);
        } catch (error) {
          reject(error);
          return;
        }

        const img = new Image();

        img.onerror = () => {
          URL.revokeObjectURL(imageUrl);
          reject(new Error('Error while loading image'));
        };

        img.onload = () => {
          const canvas = document.createElement('canvas');

          let scaleFactor = MAX_IMAGE_SIZE / Math.max(img.width, img.height);

          // Do not scale up (but still compress)
          if (scaleFactor > 1) {
            scaleFactor = 1;
          }

          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;

          try {
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          } catch (error) {
            reject(error);
            URL.revokeObjectURL(imageUrl);
            return;
          }

          canvas.toBlob(
            blob => {
              if (!blob) {
                reject(new Error('Error while compressing image'));
              } else {
                resolve(blob);
              }
              URL.revokeObjectURL(imageUrl);
            },
            'image/jpeg',
            JPG_QUALITY,
          );
        };

        img.src = imageUrl;
      });
    },
  },

  watch: {
    /**
     * Watch when new attachments are added
     * If they are images that work with html canva, check the dimensions
     * If height or width is greater than 2000px, or more than 1MB, compress the image
     *
     * Not watching "waitingFiles" because it is shared between several <Attachments> instances
     */
    async attachments(attachments) {
      let madeChanges = false;
      const updatedAttachments = [...attachments];

      this.compressing = true;
      for (let i = 0; i < updatedAttachments.length; i++) {
        const attachment = updatedAttachments[i];

        // Continue if the file is not new, not an image or already compressed
        if (
          attachment.id ||
          !attachment.type.startsWith('image/') ||
          attachment.compressed
        ) {
          continue;
        }

        const image = new Image();
        try {
          image.src = URL.createObjectURL(attachment);
          await new Promise(resolve => {
            image.onload = resolve;
          });
        } catch (error) {
          if (process.env.VUE_APP_SENTRY_DSN) {
            Sentry.captureException(error);
          } else {
            console.error('Error while loading image:', error);
          }
          continue;
        } finally {
          URL.revokeObjectURL(image.src);
        }

        // Image is already small enough, both in size and dimensions? Skip it
        if (
          image.height <= MAX_IMAGE_SIZE &&
          image.width <= MAX_IMAGE_SIZE &&
          attachment.size <= MAX_FILE_SIZE
        ) {
          continue;
        }

        let compressedImage;

        try {
          // console.log('Size before (MB):', attachment.size / 1024 / 1024);
          compressedImage = await this.compressImage(attachment);
          // console.log('Size after (MB):', compressedImage.size / 1024 / 1024);
        } catch (error) {
          if (process.env.VUE_APP_SENTRY_DSN) {
            Sentry.captureException(error);
          } else {
            console.error('Error while compressing image:', error);
          }
          continue;
        }

        // When compression has been useless, don't change the file
        if (compressedImage.size >= attachment.size) {
          attachment.compressed = true;
          continue;
        }

        // New name for the compressed file (always jpg)
        const newName =
          attachment.name.slice(0, attachment.name.lastIndexOf('.')) + '.jpg';

        const compressedFile = new File([compressedImage], newName, {
          type: 'image/jpeg',
          lastModified: Date.now(),
        });
        compressedFile.extension = 'jpg';
        // Mark the file as compressed to avoid infinite loop
        compressedFile.compressed = true;

        // Apply data from the original file to the compressed file
        compressedFile.randomId = attachment.randomId;
        compressedFile.kind = attachment.kind;

        // Find the good file in updatedWaitingFiles, with the same randomId
        const index = updatedAttachments.findIndex(
          file => file.randomId === attachment.randomId,
        );
        if (index !== -1) {
          updatedAttachments[index] = compressedFile;
          madeChanges = true;
        } else {
          console.error(`File with randomId ${attachment.randomId} not found`);
        }
      }
      this.compressing = false;

      // Avoid infinite loop
      // (because current watcher calls itself again just after - leads to inf. loop when only non-image files are added)
      if (!madeChanges) return;

      // Update "attachments" list
      this.$emit('update:attachments', updatedAttachments);

      // Update "waitingFiles" list
      const updatedWaitingFiles = [...this.waitingFiles].map(file => {
        const updatedFile = updatedAttachments.find(
          updatedFile => updatedFile.randomId === file.randomId,
        );

        return updatedFile || file;
      });
      this.$emit('update:waitingFiles', updatedWaitingFiles);
    },
  },
};
</script>

<style scoped lang="scss">
.add-attachment {
  text-transform: none;
}

ion-item {
  margin-top: 0.5rem;

  ion-img {
    max-width: 32px;
    height: 32px;
  }

  ion-label {
    font-size: 12px;
    max-width: none !important;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

ion-input {
  --background: var(--ion-color-light);
  --border-radius: 4px;
}

.w-100 {
  width: 100%;
}
</style>
