<template>
  <ion-header
    translucent="true"
    :class="{ 'header-disconnected': isPossiblyLoggedOut }"
  >
    <ion-toolbar>
      <div v-if="isPossiblyLoggedOut" class="disconnected">
        <ion-icon :icon="alertCircleOutline" color="danger" />
        <span>
          Vous semblez être deconnecté.
          <br class="ion-hide-sm-up" />
          Merci de vous&nbsp;<a href="#" @click.prevent="logout()"
            >reconnecter</a
          >.</span
        >
      </div>

      <ion-buttons v-if="backButton" slot="start">
        <ion-back-button color="dark" :default-href="routeBack">
          <ion-icon slot="icon-only" :icon="arrowBackCircleOutline" />
        </ion-back-button>
      </ion-buttons>

      <ion-title>
        {{ title }}
      </ion-title>
    </ion-toolbar>

    <ion-segment
      mode="md"
      class="segment-planning"
      v-if="this.$route.name === 'PlanningDriver'"
      :value="temporality"
      @click="setTemporality($event.target.value)"
    >
      <ion-segment-button value="past">
        <ion-label>Terminées</ion-label>
      </ion-segment-button>

      <ion-segment-button value="present">
        <ion-label>Aujourd'hui</ion-label>
      </ion-segment-button>

      <ion-segment-button value="future">
        <ion-label>À venir</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from '@ionic/vue';
import { arrowBackCircleOutline, alertCircleOutline } from 'ionicons/icons';
import {
  initInterceptor,
  isPossiblyLoggedOut,
  clearData,
} from '@/services/users';

export default {
  name: 'WhiteHeader',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonLabel,
  },
  props: {
    backButton: Boolean,
    routeBack: String,
    title: String,
    temporality: String,
    missionSelected: Boolean,
  },

  data() {
    return {
      isPossiblyLoggedOut: false,
    };
  },

  setup() {
    return {
      arrowBackCircleOutline,
      alertCircleOutline,
    };
  },

  mounted() {
    initInterceptor();
    this.refreshIsPossiblyLoggedOut();
    window.addEventListener(
      'driveapp:user_possibly_logged_out',
      this.refreshIsPossiblyLoggedOut,
    );
  },

  beforeUnmount() {
    window.removeEventListener(
      'driveapp:user_possibly_logged_out',
      this.refreshIsPossiblyLoggedOut,
    );
  },

  ionViewDidEnter() {
    this.refreshIsPossiblyLoggedOut();
  },

  methods: {
    /**
     * Set the temporality of segment
     * @param value // New Temporality of segment
     */
    setTemporality(value) {
      this.$emit('update:temporality', value);
      this.$emit('update:missionSelected', false);
    },

    refreshIsPossiblyLoggedOut() {
      this.isPossiblyLoggedOut = isPossiblyLoggedOut();
    },

    logout() {
      clearData();
      this.$router.push('/connexion');
    },
  },
};

/**
 * Please note, <style> could be just after, but adding the tag here does not work,
 * and we do not have time check why (so it's in `styleComponents.scss`).
 */
</script>
