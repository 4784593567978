import {
  storeRentalCoupon,
  updateRentalCoupon,
} from '@/services/driver/rentalCoupon';
import {
  alertController,
  loadingController,
  toastController,
} from '@ionic/vue';
import { informationCircleOutline } from 'ionicons/icons';
import utils from '@/services/utils/Utils';
import SignaturePad from 'signature_pad';

const dayjs = require('dayjs');
require('dayjs/locale/fr');
dayjs.locale('fr');

const APPROX_ANIMATION_DURATION = 300;

const ucfirst = str => str.charAt(0).toUpperCase() + str.slice(1);

const mixinDataAllTypeRentalCoupon = {
  props: {
    typeVue: String,
    typeMission: String,
  },

  data() {
    return {
      initUrl: null, // URL when the component is mounted

      user: {},
      // Data of reservation and the rental coupon that will be modified if is update
      mission: {},
      missionDetail: {},
      oldRentalCoupon: {},
      oldRentalCouponSending: false,

      // Data needed to run the form
      toggleQuantityTools: false,
      toggleComment: false,
      segmentSourceContact: 'new', // ('available', 'new')
      segmentActivities: 'prestation', // ('prestation', 'badWeather', 'maintenance')
      segmentPrestation: 'hours', // ('hours', 'days', 'package')
      segmentBadWeather: 'hours', // ('hours', 'days', 'package')
      segmentMaintenance: 'hours', // ('hours', 'days', 'package')
      startDefault: '',
      endDefault: '',
      readableDate: {},
      sourceSupervisorsBuilder: {},

      // Variable storing the data entered in the form
      start: '',
      end: '',
      source_builder_name: '',
      source_contact_id: null,
      source_contact_name: '',
      source_contact_email: '',
      source_contact_phone: '',
      source_worksite_name: '',

      times: {
        prestation: {
          name: 'prestation',
          type: 'hours', // ('hours', 'days', 'package', 'tons')
          hoursTimes: {
            type: 'hours',
            values: {},
          },
          daysTimes: {
            type: 'days',
            values: {},
          },
          nightsTimes: {
            type: 'nights',
            values: {},
          },
          packageTimes: {
            type: 'package',
            values: {},
          },
          othersTimes: {
            type: 'others',
            values: {},
          },
          tons: {},
        },
        badWeather: {
          name: 'badWeather',
          type: 'hours', // ('hours', 'days', 'package')
          hoursTimes: {
            type: 'hours',
            values: {},
          },
          daysTimes: {
            type: 'days',
            values: {},
          },
          nightsTimes: {
            type: 'nights',
            values: {},
          },
          packageTimes: {
            type: 'package',
            values: {},
          },
          othersTimes: {
            type: 'others',
            values: {},
          },
        },
        maintenance: {
          name: 'maintenance',
          type: 'hours', // ('hours', 'days', 'package')
          hoursTimes: {
            type: 'hours',
            values: {},
          },
          daysTimes: {
            type: 'days',
            values: {},
          },
          nightsTimes: {
            type: 'nights',
            values: {},
          },
          packageTimes: {
            type: 'package',
            values: {},
          },
          othersTimes: {
            type: 'others',
            values: {},
          },
        },
      },

      selectedModifiers: [],
      comments: '',
      pricing_fuel: true,
      pricing_driver: true,

      notes: '',
      worksite_reference: '',
      order_number: '',

      // signature
      did_signature_driver: false,
      canvas_signature_driver: null,
      old_draw_signature_driver: null,
      did_signature_source: false,
      canvas_signature_source: null,
      old_draw_signature_source: null,
      no_signature_source: false,
      did_signature_contact: false,
      canvas_signature_contact: null,
      old_draw_signature_contact: null,
      no_signature_contact: false,

      sendPdfToSource: false,

      // Object storing the data to be send
      rentalCoupon: {},
      initialized: false, // avoids all changes by @ionChange in the components during the loading of the data
      filesTooBig: false, // allow to delete attachments when files are too big, even if coupon status is "send"
    };
  },

  mounted() {
    this.initUrl = this.$route.fullPath;

    // `mounted` is async for rental coupons,
    // So we do not call `this.onViewEnter()` here
    // But at the end of each `mounted` method in the components that use this mixin

    // #codetag:own_lifecycle_events
    window.addEventListener('DriveApp:RouteChanged', this.onRouteChanged);
  },

  // when component unmounted, remove listeners
  unmounted() {
    // #codetag:own_lifecycle_events
    window.removeEventListener('DriveApp:RouteChanged', this.onRouteChanged);
  },

  methods: {
    onRouteChanged(event) {
      const to = event.detail.to;
      const from = event.detail.from;

      if (this.initUrl === from.fullPath) {
        this.onViewLeave();
      }

      if (this.initUrl === to.fullPath) {
        this.onViewEnter();
      }
    },

    // Replaces `ionViewDidEnter` (not working correcly for this precise components+mixin combo)
    onViewEnter() {
      this.initializationSignatureData();
    },

    // Replaces `ionViewWillLeave` (not working correcly for this precise components+mixin combo)
    onViewLeave() {
      this.removeSignatureListeners();
    },

    /**
     * Retrieves the name of the site according to the information we have
     */
    setWorksiteTextForm(
      oldWorksiteName,
      missionWorksiteName,
      missionWorksiteAddress,
      missionWorksiteCity,
      missionWorksiteManual,
    ) {
      if (this.typeVue !== 'create' && oldWorksiteName) {
        return oldWorksiteName;
      }

      if (missionWorksiteManual) {
        return missionWorksiteManual;
      }

      if (
        missionWorksiteCity ||
        missionWorksiteName ||
        missionWorksiteAddress
      ) {
        const tab = [
          missionWorksiteCity,
          missionWorksiteName,
        ];

        if (missionWorksiteName  !== missionWorksiteAddress) {
          tab.push(missionWorksiteAddress);
        }

        return tab.filter(v => !!v).join(' - ');
      }

      return null;
    },

    /**
     * Fills in the input fields depending on the start and end date of the mission
     */
    setInformationsDateDependent() {
      let startDate = dayjs(this.start);
      let endDate = dayjs(this.end);

      if (this.typeVue !== 'create') {
        this.notes = this.oldRentalCoupon.notes;

        this.times.prestation.type = this.oldRentalCoupon.prestation_times.type;
        this.times.badWeather.type = this.oldRentalCoupon.bad_weather_times.type;
        this.times.maintenance.type = this.oldRentalCoupon.maintenance_times.type;
      }

      while (endDate.diff(startDate, 'day') >= 0) {
        let key = startDate.format('YYYYMMDD');
        this.readableDate[key] = startDate.format('ddd DD MMM');

        if (this.typeVue !== 'create') {
          this.setTimes(
            this.times.prestation,
            this.oldRentalCoupon.prestation_times,
            key,
            this.times.prestation.type,
          );
          this.setTimes(
            this.times.badWeather,
            this.oldRentalCoupon.bad_weather_times,
            key,
            this.times.badWeather.type,
          );
          this.setTimes(
            this.times.maintenance,
            this.oldRentalCoupon.maintenance_times,
            key,
            this.times.maintenance.type,
          );
        } else {
          let item = this.typeMission === 'reservation' ? this.mission.pricing_information : this.missionItem.pricing_information;
          if (! item) {
            item = {
              id: null,
              isOverride: null,
            }
          }
          this.times.prestation.hoursTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: dayjs()
                .startOf('day')
                .format('HH:mm'),
          };
          this.times.prestation.daysTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: '0',
          };
          this.times.prestation.nightsTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: '0',
          };
          this.times.prestation.packageTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: '0',
          };
          this.times.prestation.othersTimes.values[key] = {
            id: item.id,
            isOverride: item.isOverride,
            value: '0',
          };

          this.times.badWeather.hoursTimes.values[key] = {
            value: dayjs()
                .startOf('day')
                .format('HH:mm'),
          };
          this.times.badWeather.daysTimes.values[key] = { value: '0' };
          this.times.badWeather.nightsTimes.values[key] = { value: '0' };
          this.times.badWeather.packageTimes.values[key] = { value: '0' };
          this.times.badWeather.othersTimes.values[key] = { value: '0' };

          this.times.maintenance.hoursTimes.values[key] = {
            value: dayjs()
                .startOf('day')
                .format('HH:mm'),
          };
          this.times.maintenance.daysTimes.values[key] = { value: '0' };
          this.times.maintenance.nightsTimes.values[key] = { value: '0' };
          this.times.maintenance.packageTimes.values[key] = { value: '0' };
          this.times.maintenance.othersTimes.values[key] = { value: '0' };
        }

        startDate = startDate.day(startDate.day() + 1);
      }
    },

    setTimes(timeSelected, oldTimeSelected, key, type) {
      if (type === 'hours') {
        timeSelected.hoursTimes.values[key] = oldTimeSelected.values[key];
        timeSelected.daysTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.nightsTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.packageTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.othersTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
      } else if (type === 'days') {
        timeSelected.hoursTimes.values[key] = {
            id: null,
            isOverride: null,
            value: dayjs().startOf('day').format('HH:mm'),
        };
        timeSelected.daysTimes.values[key] = {
            id: oldTimeSelected.values[key].id,
            isOverride: oldTimeSelected.values[key].isOverride,
            value: oldTimeSelected.values[key].value,
        };
        timeSelected.nightsTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.packageTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.othersTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
      } else if (type === 'nights') {
        timeSelected.hoursTimes.values[key] = {
            id: null,
            isOverride: null,
            value: dayjs().startOf('day').format('HH:mm'),
        };
        timeSelected.daysTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.nightsTimes.values[key] = {
          id: oldTimeSelected.values[key].id,
          isOverride: oldTimeSelected.values[key].isOverride,
          value: oldTimeSelected.values[key].value,
        };
        timeSelected.packageTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.othersTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
      } else if (type === 'package') {
        timeSelected.hoursTimes.values[key] = {
          id: null,
          isOverride: null,
          value: dayjs().startOf('day').format('HH:mm'),
        };
        timeSelected.daysTimes[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.nightsTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.packageTimes.values[key] = {
          id: oldTimeSelected.values[key].id,
          isOverride: oldTimeSelected.values[key].isOverride,
          value: oldTimeSelected.values[key].value,
        };
      } else {
        timeSelected.hoursTimes.values[key] = {
          id: null,
          isOverride: null,
          value: dayjs().startOf('day').format('HH:mm'),
        };
        timeSelected.daysTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.nightsTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.packageTimes.values[key] = {
          id: null,
          isOverride: null,
          value: '0',
        };
        timeSelected.othersTimes.values[key] = {
          id: oldTimeSelected.values[key].id,
          isOverride: oldTimeSelected.values[key].isOverride,
          value: oldTimeSelected.values[key].value,
        };
      }
    },

    /**
     * To be used in the case of a rental coupon modification
     */
    setToggle(typeMission) {
      if (this.comments) {
        this.toggleComment = true;
      }

      if (typeMission !== 'reservation') {
        if (typeMission === 'livraison') {
          this.toggleQuantityTools = true;
        }
        return;
      }

      if (this.machine_counter_start || this.machine_counter_end) {
        this.toggleMachineCounter = true;
      }
    },

    /**
     * When the dates of the rental coupon are changed, the variables dependent on these dates are updated
     */
    updateInformationsDateDependent() {
      let startDate = dayjs(this.start);
      let endDate = dayjs(this.end);

      let newReadableDate = {};
      let newPrestationsTimesHours = { type: 'hours',  values: {} };
      let newPrestationsTimesDays = { type: 'days',  values: {} };
      let newPrestationsTimesNights = { type: 'nights',  values: {} };
      let newPrestationsTimesPackage = { type: 'package',  values: {} };
      let newPrestationsTimesOthers = { type: 'others',  values: {} };

      let newBadWeatherTimesHours = { type: 'hours',  values: {} };
      let newBadWeatherTimesDays = { type: 'days',  values: {} };
      let newBadWeatherTimesNights = { type: 'nights',  values: {} };
      let newBadWeatherTimesPackage = { type: 'package',  values: {} };
      let newBadWeatherTimesOthers = { type: 'others',  values: {} };

      let newMaintenanceTimesHours = { type: 'hours',  values: {} };
      let newMaintenanceTimesDays = { type: 'days',  values: {} };
      let newMaintenanceTimesNights = { type: 'days',  values: {} };
      let newMaintenanceTimesPackage = { type: 'package',  values: {} };
      let newMaintenanceTimesOthers = { type: 'others',  values: {} };

      while (endDate.diff(startDate, 'day') >= 0) {
        let key = startDate.format('YYYYMMDD');

        let dateAvailable = false;

        for (const oldKey in this.readableDate) {
          if (key === oldKey) {
            dateAvailable = true;

            newReadableDate[key] = this.readableDate[key];
            newPrestationsTimesHours.values[key] =
              this.times.prestation.hoursTimes.values[key];
            newPrestationsTimesDays.values[key] = this.times.prestation.daysTimes.values[key];
            newPrestationsTimesNights.values[key] = this.times.prestation.nightsTimes.values[key];
            newPrestationsTimesPackage.values[key] =
              this.times.prestation.packageTimes.values[key];
            newPrestationsTimesOthers.values[key] = this.times.prestation.othersTimes.values[key];

            newBadWeatherTimesHours.values[key] =
              this.times.badWeather.hoursTimes.values[key];
            newBadWeatherTimesDays.values[key] = this.times.badWeather.daysTimes.values[key];
            newBadWeatherTimesNights.values[key] = this.times.badWeather.nightsTimes.values[key];
            newBadWeatherTimesPackage.values[key] =
              this.times.badWeather.packageTimes.values[key];
            newBadWeatherTimesOthers.values[key] = this.times.prestation.othersTimes.values[key];

            newMaintenanceTimesHours.values[key] =
              this.times.maintenance.hoursTimes.values[key];
            newMaintenanceTimesDays.values[key] =
              this.times.maintenance.daysTimes.values[key];
            newMaintenanceTimesNights.values[key] =
              this.times.maintenance.nightsTimes.values[key];
            newMaintenanceTimesPackage.values[key] =
              this.times.maintenance.packageTimes.values[key];
            newMaintenanceTimesOthers.values[key] = this.times.prestation.othersTimes.values[key];
          }
        }

        if (!dateAvailable) {
          let readable = startDate.format('ddd DD MMM');
          newReadableDate[key] = readable;

          newPrestationsTimesHours.values[key] = {
            id: null,
            isOverrride: null,
            value: dayjs().startOf('day').format('HH:mm'),
          };
          newPrestationsTimesDays.values[key] = {
            id: null,
            isOverrride: null,
            value: '0',
          };
          newPrestationsTimesPackage.values[key] = {
            id: null,
            isOverrride: null,
            value: '0',
          };
          newPrestationsTimesOthers.values[key] = {
            id: null,
            isOverrride: null,
            value: '0',
          }

          newBadWeatherTimesHours.values[key] = {
            'value': dayjs().startOf('day').format('HH:mm')
          };
          newBadWeatherTimesDays.values[key] = { value: '0' };
          newBadWeatherTimesPackage.values[key] = { value: '0' };

          newMaintenanceTimesHours.values[key] = {
            'value': dayjs().startOf('day').format('HH:mm')
          };
          newMaintenanceTimesDays.values[key] = { value: '0' };
          newMaintenanceTimesPackage.values[key] = { value: '0' };
        }

        startDate = startDate.day(startDate.day() + 1);
      }

      this.readableDate = newReadableDate;
      this.times.prestation.hoursTimes = newPrestationsTimesHours;
      this.times.prestation.daysTimes = newPrestationsTimesDays;
      this.times.prestation.nightsTimes = newPrestationsTimesNights;
      this.times.prestation.packageTimes = newPrestationsTimesPackage;
      this.times.prestation.othersTimes = newPrestationsTimesOthers;
      this.times.badWeather.hoursTimes = newBadWeatherTimesHours;
      this.times.badWeather.daysTimes = newBadWeatherTimesDays;
      this.times.badWeather.nightsTimes = newBadWeatherTimesNights;
      this.times.badWeather.packageTimes = newBadWeatherTimesPackage;
      this.times.badWeather.othersTimes = newPrestationsTimesOthers;
      this.times.maintenance.hoursTimes = newMaintenanceTimesHours;
      this.times.maintenance.daysTimes = newMaintenanceTimesDays;
      this.times.maintenance.nightsTimes = newMaintenanceTimesNights;
      this.times.maintenance.packageTimes = newMaintenanceTimesPackage;
      this.times.maintenance.othersTimes = newPrestationsTimesOthers;
    },

    async sendWithNoEmail() {
      const popUp = await alertController.create({
        header: '⚠  aucune adresse mail renseignée pour le contact',
        message:
          "<br>Saisissez une adresse mail ou envoyez uniquement au bureau",
        buttons: [
          {
            text: 'Saisir',
            role: 'cancel',
            handler: () => {
              return false;
            },
          },
          {
            text: 'Envoyer',
            handler: () => {
              return true;
            },
          },
        ],
      });
      await popUp.present();

      const { role } = await popUp.onDidDismiss();
      return role;
    },

    async send(waitingFiles) {
      const result = utils.checkFilesSize(waitingFiles);
      if (!result.isOk) {
        utils.sendFilesErrorMessage(result.message);
        this.filesTooBig = true;

        return;
      }

      let messageLoading = "En cours d'enregistrement...";
      if (this.rentalCoupon.status === 'send') {
        messageLoading = "En cours d'envoi...";
      }

      const loading = await loadingController.create({
        message: messageLoading,
      });
      await loading.present();

      const data = this.getWaitingFilesFormData(waitingFiles);

      if (this.sendPdfToSource) {
        this.rentalCoupon.sendPdfToSource = true;
      }

      data.append('coupon_data', JSON.stringify(this.rentalCoupon));

      let response;
      try {
        if (this.typeVue === 'create') {
          response = await storeRentalCoupon(data);
        } else {
          response = await updateRentalCoupon(data, this.rentalCoupon.id);
        }
      } catch (e) {
        await loading.dismiss();
        await utils.onError(e);
        return;
      }
      await loading.dismiss();

      if (response.status !== 'ok') {
        await utils.onError();
        return;
      }

      const toast = await toastController.create({
        message: response.flash_message,
        duration: 5000,
        color: 'success',
        position: 'top',
        cssClass: 'toast-rental-coupon',
      });
      await toast.present();

      if (response.complement) {
        const errorToast = await toastController.create({
          header: 'Erreur',
          message: response.complement,
          duration: 5000,
          color: 'danger',
          position: 'top',
          cssClass: 'toast-rental-coupon',
        });

        await errorToast.present();
      }

      return response;
    },

    getWaitingFilesFormData(waitingFiles) {
      const formData = new FormData();

      // add all waiting files to our formData
      Object.values(waitingFiles).forEach(waitingFile => {
        let key = `${waitingFile.kind}_files`;

        formData.append(
          `${key}[]`,
          waitingFile,
          waitingFile.newName || waitingFile.name,
        );
      });

      if (
        Object.values(waitingFiles).filter(f => f.kind === 'comments').length
      ) {
        formData.append('keys[]', `comments_files`);
      }

      if (
        Object.values(waitingFiles).filter(
          f => f.kind === 'internal_information',
        ).length
      ) {
        formData.append('keys[]', `internal_information_files`);
      }

      return formData;
    },

    completeTimes(timeSelected) {
      if (timeSelected.type === 'hours') {
        return timeSelected.hoursTimes;
      }

      let times = {
        type: timeSelected.type,
        values: {}
      };

      let time = {};
      for (const key in this.readableDate) {
        if (timeSelected.type === 'days') {
          time = timeSelected.daysTimes.values[key];
        } else if (timeSelected.type === 'nights') {
          time = timeSelected.nightsTimes.values[key];
        } else if (timeSelected.type.includes('package'))  {
          time = timeSelected.packageTimes.values[key];
        } else {
          time = timeSelected.othersTimes.values[key];
        }

        times.values[key] = {
          'id': time.id,
          'isOverride': time.isOverride,
          'value': time.value,
        };
      }
      return times;
    },

    completeMachineCounteur() {
      if (this.machine_counter_start) {
        this.rentalCoupon.machine_counter_start = parseInt(
          this.machine_counter_start,
        );
      }

      if (this.machine_counter_end) {
        this.rentalCoupon.machine_counter_end = parseInt(
          this.machine_counter_end,
        );
      }
    },

    resizeGivenRef(ref, ratio) {
      const signatureCanvas = this.$refs.signature.$refs[ref];
      if (!signatureCanvas) return;

      const canvasData = this[`canvas_signature_${ref}`];
      const {
        // Get the current canvas dimensions
        width: oldWidth,
        height: oldHeight,
        offsetWidth,
        offsetHeight,
      } = signatureCanvas;

      // Copy signature data
      const copy = canvasData.toData();

      // Calculate new canvas dimensions
      const newWidth = offsetWidth * ratio;
      const newHeight = offsetHeight * ratio;

      // Resize the canvas
      signatureCanvas.width = newWidth;
      signatureCanvas.height = newHeight;

      // Scale the drawing context for high DPI screens
      signatureCanvas.getContext('2d').scale(ratio, ratio);

      // Ensure that copy is an array
      if (!Array.isArray(copy)) {
        console.error(`Signature data for ${ref} is not an array:`, copy);
        return;
      }

      if (!copy.length) {
        return;
      }

      // Calculate scaling factors based on new and old dimensions
      const scaleX = newWidth / oldWidth;
      const scaleY = newHeight / oldHeight;

      // Scale each point in the signature data
      const scaledData = copy.map(line => ({
        ...line,
        points: line.points.map(point => ({
          ...point,
          x: point.x * scaleX,
          y: point.y * scaleY,
        })),
      }));

      // Restore the scaled signature data
      canvasData.fromData(scaledData);
    },

    // source: https://github.com/szimek/signature_pad?tab=readme-ov-file#handling-high-dpi-screens
    resizeCanvas() {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.resizeGivenRef('contact', ratio);
      this.resizeGivenRef('driver', ratio);
      this.resizeGivenRef('source', ratio);
    },

    // remove initalized listeners inside "initializationSignatureData"
    removeSignatureListeners() {
      window.removeEventListener('resize', this.resizeCanvas);

      ['source', 'contact', 'driver'].forEach(key => {
        if (this[`canvas_signature_${key}`]) {
          this[`canvas_signature_${key}`].removeEventListener(
            'beginStroke',
            this[`startSignature${ucfirst(key)}`],
          );

          // Clear & de-activate
          this.clearSignature(key);
          this[`canvas_signature_${key}`].off();
        }
      });
    },

    /**
     * Allows the signature system to be linked to the canvas tags that need it
     */
    initializationSignatureData() {

      if (!this.$refs.signature) return;

      ['source', 'contact', 'driver'].forEach(key => {
        if (!this.$refs.signature.$refs[key]) {
          return;
        }

        if (this[`canvas_signature_${key}`]) {
          this[`canvas_signature_${key}`].on();
        } else {
          this[`canvas_signature_${key}`] = new SignaturePad(
            this.$refs.signature.$refs[key],
          );
        }

        this[`canvas_signature_${key}`].addEventListener(
          'beginStroke',
          this[`startSignature${ucfirst(key)}`],
        );
      });

      window.addEventListener('resize', this.resizeCanvas);
      // Without using a timeout, the "new" canvas size is 0 (because page is hidden)
      // So ratio computation is wrong (* Infinity)
      setTimeout(this.resizeCanvas, APPROX_ANIMATION_DURATION);
    },

    startSignatureSource() {
      this.did_signature_source = true;
    },

    startSignatureContact() {
      this.did_signature_contact = true;
    },

    startSignatureDriver() {
      this.did_signature_driver = true;
    },

    clearSignature(signature) {
      const key = `signature_${signature}`;

      this[`canvas_${key}`].clear();
      this[key] = null;
      this[`did_${key}`] = false;
    },

    saveSignature(signature) {
      const key = `signature_${signature}`;

      if (this[`old_draw_${key}`]) {
        this.rentalCoupon[key] = this[`old_draw_${key}`];
        return;
      }

      if (!this[`did_${key}`]) {
        return;
      }

      let dataSignature = this[`canvas_${key}`].toDataURL();
      this.rentalCoupon[key] = dataSignature;
    },

    setInternalReferences() {
      if (!this.mission && !this.missionItem) {
        return;
      }

      const parent = this.missionItem ?? this.mission;
      this.worksite_reference = parent.worksite_reference;
      this.order_number = parent.order_number;
    },

    setRoadInfo() {
      if (!this.mission) {
        return;
      }

      const parent = this.mission;
      this.pricing_fuel = parent.pricing_fuel || false;
      this.pricing_driver = parent.pricing_driver || false;
    },

    setSelectedModifiers(modifiers) {
      if (!modifiers) return [];

      let startDate = dayjs(this.start);
      let endDate = dayjs(this.end);

      //map to get an array with only 'name' columns
      const arrayColumn = modifiers.map(item => item.name);

      // get unique name before foreach
      modifiers.filter((value, index) => {
        return arrayColumn.indexOf(value.name) === index;
      }).forEach((element) => {
        this.selectedModifiers[element.name] = {
          'name': element.name,
          'type': element.unit_primary,
          'values': {},
        };

        if (startDate && endDate && (endDate.isAfter(startDate) || endDate.isSame(startDate))) {
          while (endDate.diff(startDate, 'day') >= 0) {
            let key = startDate.format('YYYYMMDD');

            if (element.unit_primary === 'heure') {
              this.selectedModifiers[element.name].values[key] = {
                'id': element.override_id ? element.override_id : element.id,
                'isOverride': !!element.override_id,
                'value': dayjs()
                    .startOf('day')
                    .format('HH:mm'),
              };
            } else {
              this.selectedModifiers[element.name].values[key] = {
                'id': element.override_id ? element.override_id : element.id,
                'isOverride': !!element.override_id,
                'value': '0',
              };
            }

            startDate = startDate.day(startDate.day() + 1);
          }
        }

        startDate = dayjs(this.start);
      });
    },

    getPrestationType(item) {
      const type = item.pricing_information.unit_primary;

      if (type === 'forfait') {
        return 'package';
      } else if (type === 'jour') {
        return 'days';
      } else if (type === 'heure') {
        return 'hours';
      } else if (type === 'nuit') {
        return 'nights';
      }

      return type;
    },

    handleContactSegmentChange(kind, newVal) {
      if (newVal === 'new') {
        // Resetting `id` is not needed because not sent when segment = `new`.
        // See for example `completeSourceContact`.
        this[`${kind}_contact_name`] = '';
        this[`${kind}_contact_email`] = '';
        this[`${kind}_contact_phone`] = '';
        return;
      }

      // Simply switch back to old contact data, when available
      if (
        newVal === 'available' &&
        this.oldRentalCoupon &&
        this.oldRentalCoupon.id
      ) {
        this[`${kind}_contact_id`] = this.oldRentalCoupon[`${kind}_contact_id`];
        this[`${kind}_contact_name`] =
          this.oldRentalCoupon[`${kind}_contact_name`];
        // Phone and email updated by other watchers
        return;
      }

      // Put back reseted email & phone if an id is still selected
      if (newVal === 'available' && this[`${kind}_contact_id`]) {
        const contact = this[`${kind}SupervisorsBuilder`].find(
          c => c.id === this[`${kind}_contact_id`],
        );

        this[`${kind}_contact_email`] =
          contact && contact.email ? contact.email : '';
        this[`${kind}_contact_phone`] =
          contact && contact.phone ? contact.phone : '';
      }
    },

    handleContactIdChange(kind, newVal) {
      if (
        this.typeMission !== 'reservation' &&
        this.missionItem.coupon_company !== kind &&
        !this.isPreliminaryStep
      ) {
        return;
      }

      const contact = this[`${kind}SupervisorsBuilder`].find(
        c => c.id === newVal,
      );

      /**
       * For draft or sent coupons where user modified the contact email or phone,
       * put back those overriden values.
       */
      if (
        this.typeVue !== 'create' &&
        this.oldRentalCoupon &&
        this.oldRentalCoupon[`${kind}_contact_id`] &&
        this.oldRentalCoupon[`${kind}_contact_id`] === newVal
      ) {
        this[`${kind}_contact_email`] =
          this.oldRentalCoupon[`${kind}_contact_email`];
        this[`${kind}_contact_phone`] =
          this.oldRentalCoupon[`${kind}_contact_phone`];
        return;
      }

      this[`${kind}_contact_email`] =
        contact && contact.email ? contact.email : '';
      this[`${kind}_contact_phone`] =
        contact && contact.phone ? contact.phone : '';
    },

    hasTimes() {
      const type = this.times.prestation.type;
      let goodTimes = this.times.prestation.othersTimes;
      let hasTimes = false;

      if (
        type === 'hours' ||
        type === 'days' ||
        type === 'nights' ||
        type === 'package'
      ) {
        goodTimes = this.times.prestation[`${type}Times`];
      }

      const findHasTimes = value => {
        if (value.value !== '0' && value.value !== '00:00') {
          hasTimes = true;
        }
      };

      // first check if prestation is empty
      Object.values(goodTimes.values).forEach(findHasTimes);

      // check for the modifiers only if we don't have any prestation
      if (!hasTimes) {
        Object.values(this.selectedModifiers).forEach(modifier => {
          Object.values(modifier.values).forEach(findHasTimes);
        });
      }

      // if still no time found, check for the bad weather
      if (!hasTimes) {
        Object.values(this.times.badWeather.hoursTimes.values).forEach(
          findHasTimes,
        );
      }

      // if still no time found, check for the maintenance
      if (!hasTimes) {
        Object.values(this.times.maintenance.hoursTimes.values).forEach(
          findHasTimes,
        );
      }

      return hasTimes;
    },

    getNotSendableReason() {
      if (!this.hasTimes()) {
        return 'Envoi impossible : aucune quantité entrée.';
      }

      if (!this.did_signature_driver && !this.old_draw_signature_driver) {
        return "Envoi impossible. Vous n'avez pas signé le BL.";
      }

      if (
        !this.did_signature_contact &&
        !this.no_signature_contact &&
        !this.old_draw_signature_contact
      ) {
        return "Envoi impossible. Le client n'a pas signé le BL.";
      }

      if (
        !this.user.company.email &&
        !this.source_contact_email &&
        !this.target_contact_email
      ) {
        return 'Envoi impossible. Aucune adresse mail renseignée.';
      }
    },

    async sendCouponNotSendableToast() {
      const toast = await toastController.create({
        message: this.getNotSendableReason(),
        duration: 3000,
        color: 'secondary',
        position: 'top',
        icon: informationCircleOutline,
      });

      await toast.present();
    },
  },

  computed: {
    routeBack() {
      if (!this.$route) return null;

      if (this.typeMission === 'reservation') {
        return `/chantier/${this.$route.params.id}/${this.$route.params.idRide}`;
      }

      return `/${this.typeMission}/${this.$route.params.id}/${this.$route.params.idRide}/${this.$route.params.idItem}`;
    },

    status() {
      if (
        (this.did_signature_driver || this.old_draw_signature_driver) &&
        (this.did_signature_contact ||
          this.no_signature_contact ||
          this.old_draw_signature_contact)
      ) {
        if (
          (this.user.company.email ||
            this.source_contact_email ||
            this.target_contact_email) &&
          this.hasTimes()
        ) {
          return 'send';
        }
        return 'save';
      }

      return 'save';
    },

    /**
     * Sometimes, coupons need to be sent to the source company,
     * with only partial data.
     *
     * Only for transportItems with `need_signature_source` set to true,
     * And not already sent to the source
     */
    isPreliminaryStep() {
      // Not a transportItem
      if (!this.missionItem) return false;

      // Signature source not asked
      if (!this.missionItem.need_signature_source) return false;

      // Already sent to the source
      if (this.oldRentalCoupon && this.oldRentalCoupon.signature_source)
        return false;
      if (this.oldRentalCoupon && this.oldRentalCoupon.no_signature_source)
        return false;

      // e.g typeView = create or coupon is draft
      return true;
    },

    isSourceSendable() {
      if (
        this.oldRentalCoupon &&
        this.oldRentalCoupon.id &&
        (this.old_draw_signature_source ||
          this.oldRentalCoupon.no_signature_source)
      )
        return false;

      return this.did_signature_source || this.no_signature_source;
    },

    isCouponSendable() {
      if (this.oldRentalCoupon && this.oldRentalCoupon.status === 'send') {
        return (
          (this.oldRentalCoupon.source_contact_email &&
            this.oldRentalCoupon.source_contact_email !==
              this.source_contact_email) ||
          (this.oldRentalCoupon.target_contact_email &&
            this.oldRentalCoupon.target_contact_email !==
              this.target_contact_email)
        );
      }

      return this.status === 'send';
    },
  },

  watch: {
    /**
     * When user select "new contact", reset fields
     * But when user goes back to "available contact", put back old values
     * Equivalent to old `updateSegmentContact`
     */
    segmentSourceContact(newVal) {
      this.handleContactSegmentChange('source', newVal);
    },
    segmentTargetContact(newVal) {
      this.handleContactSegmentChange('target', newVal);
    },

    /**
     * When an existing contact is selected, apply its email & phone (if any) to the ViewModel*
     * Equivalent to old `updateContactEmail`
     */
    source_contact_id(newVal) {
      this.handleContactIdChange('source', newVal);
    },
    target_contact_id(newVal) {
      this.handleContactIdChange('target', newVal);
    },
  },
};

export { mixinDataAllTypeRentalCoupon };
